import {USER_ROLES} from '../constants/userRoles'
import {getTokens} from '../utils/tokens'
import {getCurrentReleaseMeta} from '../pages/ChemoSafeTool/utils'
import {ServiceAbstract} from './AbstractService'
import {ChemosafeDocument, CSTRelease, CSTReleaseMeta, Tokens, User, PublicProfile, ChangeLog} from './types'

interface SignUp {
  email: string
  password: string
  firstName: string
  lastName: string
  position: string
  country: string
  institution: string
  isCoalitionMemberRequest: boolean
  isRequestAccessToChemoquant: boolean
  publicProfile?: {
    name?: string
    summary?: string
    interests?: string
    twitter?: string
    linkedIn?: string
    googleScholar?: boolean
    imgUrl?: string
  }
}

interface SignIn {
  email: string
  password: string
}
interface ChangeEmail {
  newEmail: string
  password: string
}

interface ChangePassword {
  newPassword: string
}

interface UpdateUser {
  institution?: string
  country?: string
  position?: string
  firstName?: string
  lastName?: string
  city?: string
  jobRole?: string
  speciality?: string[]
  publicProfile?: PublicProfile
}

interface UpdateUserByAdmin extends UpdateUser {
  userId?: string
  isLimitAccess?: boolean
}
interface ForgotPassword {
  email: string
}

interface ChangeUserRole {
  userId: string
  role: USER_ROLES
}
interface UpdateAvatar {
  file: File
}
interface UpdateAvatarByAdmin extends UpdateAvatar {
  userId: string
}
interface ResendEmailConfirm {
  email: string
}

interface ContactUs {
  name: string
  country: string
  email: string
  institution?: string
  commentType?: string
  comment: string
}
interface DenyCoalitionRequest {
  userId: string
}

interface DenyChemoquantRequest {
  userId: string
}

interface UpdateReference {
  id: string
  fileName?: string
  fileLocation?: string
}
interface UpdateResourceName {
  id: string
  fileName: string
}
interface UpdateResourceWithNewFile {
  id: string
  fileName: string
  file: File
}
interface SaveReverence {
  fileName: string
  fileLocation: string
}
interface SaveResource {
  fileName: string
  file: File
}

interface DeleteUserByAdmin {
  userId: string
}

type SaveOrUpdateCSTRelease = Pick<CSTRelease, 'assessment'>

export type DataTypes =
  | UpdateUser
  | ChangePassword
  | ChangeEmail
  | SignUp
  | SignIn
  | ForgotPassword
  | ChangeUserRole
  | UpdateAvatar
  | ResendEmailConfirm
  | ContactUs
  | DenyCoalitionRequest
  | DeleteUserByAdmin
  | SaveOrUpdateCSTRelease
  | SaveReverence
  | UpdateReference

export default class MidtierService extends ServiceAbstract {
  public static saveResource = (data: SaveResource): Promise<ChemosafeDocument> => {
    return new Promise(async (resolve, reject) => {
      try {
        const tokens = await getTokens()
        const fd = new FormData()
        fd.append('fileName', data.fileName)
        fd.append('file', data.file)
        const response = await MidtierService.axiosInstance.post('/chemosafeDocuments/upload', fd, {
          headers: {
            Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
          },
        })
        resolve(response.data.chemosafeDocument)
      } catch (e) {
        reject(e)
      }
    })
  }
  public static saveReference = async (data: SaveReverence): Promise<ChemosafeDocument> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      data,
      url: `/chemosafeDocuments`,
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      methodType: 'post',
    })
    return response.data.chemosafeDocument
  }

  public static getAllChemosafeDocuments = async (): Promise<ChemosafeDocument[]> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      url: `/chemosafeDocuments`,
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      methodType: 'get',
    })
    return response.data.chemosafeDocuments
  }
  public static editReference = async (data: UpdateReference): Promise<ChemosafeDocument> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      data,
      url: `/chemosafeDocuments/reference`,
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      methodType: 'put',
    })
    return response.data.chemosafeDocument
  }

  public static editResourceName = async (data: UpdateResourceName): Promise<ChemosafeDocument> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      data,
      url: `/chemosafeDocuments/resource`,
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      methodType: 'put',
    })
    return response.data.chemosafeDocument
  }
  public static editResourceWithNewFile = (data: UpdateResourceWithNewFile): Promise<ChemosafeDocument> => {
    return new Promise(async (resolve, reject) => {
      try {
        const tokens = await getTokens()
        const fd = new FormData()
        fd.append('id', data.id)
        fd.append('fileName', data.fileName)
        fd.append('file', data.file)
        const response = await MidtierService.axiosInstance.put('/chemosafeDocuments/upload', fd, {
          headers: {
            Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
          },
        })
        resolve(response.data.chemosafeDocument)
      } catch (e) {
        reject(e)
      }
    })
  }
  public static getQuestionsBlacklist = async (): Promise<string[]> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      url: '/assessmentMeta/deletedQuestionsIds',
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      methodType: 'get',
    })
    return response.data.deletedIds
  }

  public static getCSTReleaseById = async (id: string): Promise<CSTRelease> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      url: `/assessmentMeta/${id}`,
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      methodType: 'get',
    })
    return response.data.assessmentMeta
  }

  public static getAllCSTReleases = async (): Promise<CSTReleaseMeta[]> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      url: `/assessmentMeta`,
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      methodType: 'get',
    })
    return response.data.assessmentMetas
  }

  // TODO: fetch blacklist only on add question
  public static getCurrentRelease = async (): Promise<{
    currentRelease: CSTRelease
    currentActiveRelease?: CSTReleaseMeta
    blacklist: string[]
  }> => {
    const releases = await MidtierService.getAllCSTReleases()
    const currentActiveRelease = releases.find(({isActiveRelease}) => isActiveRelease)
    const currentReleaseMeta = getCurrentReleaseMeta(releases)
    const currentRelease = await MidtierService.getCSTReleaseById(currentReleaseMeta.id)
    const blacklist = await MidtierService.getQuestionsBlacklist()
    return {currentRelease, blacklist, currentActiveRelease}
  }

  public static makeNewCSTrelease = async (id: string): Promise<CSTRelease> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      url: `/assessmentMeta/release/${id}`,
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      methodType: 'put',
    })
    return response.data.assessmentMeta
  }

  public static saveCSTRelease = async (
    questionnaire: CSTRelease['assessment']['subjectAreas'],
  ): Promise<CSTRelease> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      data: {assessment: {subjectAreas: questionnaire}},
      url: `/assessmentMeta`,
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      methodType: 'post',
    })
    return response.data.assessmentMeta
  }

  public static updateCSTRelease = async (
    id: string,
    questionnaire: CSTRelease['assessment']['subjectAreas'],
  ): Promise<CSTRelease> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      data: {assessment: {subjectAreas: questionnaire}},
      url: `/assessmentMeta/${id}`,
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      methodType: 'put',
    })
    return response.data.assessmentMeta
  }

  public static getChangelogForVersion = async (version: number): Promise<ChangeLog[]> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      url: `/assessmentMeta/changelog/version/${version}`,
      methodType: 'get',
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
    })
    return response.data.changelog
  }
  public static sendContactUs = async (data: ContactUs): Promise<void> => {
    await MidtierService.templateMethod({
      url: '/contacts',
      data,
      methodType: 'post',
    })
    return Promise.resolve()
  }

  public static updateUser = async (data: UpdateUser): Promise<User> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      url: '/users',
      data,
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      methodType: 'put',
    })
    return response.data.user
  }

  public static changePassword = async (data: ChangePassword): Promise<Tokens> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      url: '/users/changePassword',
      data,
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      methodType: 'post',
    })
    return response.data.tokens
  }

  public static changeEmail = async (data: ChangeEmail): Promise<Tokens> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      url: '/users/changeEmail',
      data,
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      methodType: 'post',
    })
    return response.data.tokens
  }

  public static signUp = async (userData: SignUp): Promise<void> => {
    await MidtierService.templateMethod({
      url: '/users/register',
      data: userData,
      methodType: 'post',
    })
    return Promise.resolve()
  }

  public static signIn = async (userData: SignIn): Promise<Tokens> => {
    console.log(process.env)
    const response = await MidtierService.templateMethod({
      url: 'users/mobile/auth',
      data: userData,
      methodType: 'post',
    })
    return response.data.tokens
  }

  public static getUser = async (): Promise<User> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      url: '/users/myself',
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      methodType: 'get',
    })
    return response.data.user
  }

  public static forgotPassword = async (data: ForgotPassword): Promise<void | never> => {
    await MidtierService.templateMethod({
      url: '/users/forgotPassword',
      data,
      methodType: 'post',
    })
    return Promise.resolve()
  }

  public static getAllUsers = async (): Promise<Array<User>> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      url: '/users',
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      methodType: 'get',
    })
    return response.data.users
  }

  public static addUserRole = async (data: ChangeUserRole): Promise<void> => {
    const tokens = await getTokens()
    await MidtierService.templateMethod({
      url: '/users/addRole',
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      data,
      methodType: 'put',
    })
    return Promise.resolve()
  }

  public static delUserRole = async (data: ChangeUserRole): Promise<void> => {
    const tokens = await getTokens()
    await MidtierService.templateMethod({
      url: '/users/delRole',
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      data,
      methodType: 'put',
    })
    return Promise.resolve()
  }

  public static resendEmailConfirm = async (data: ResendEmailConfirm): Promise<void> => {
    await MidtierService.templateMethod({
      url: '/users/resendEmailConfirm',
      data,
      methodType: 'post',
    })
    return Promise.resolve()
  }

  public static updateAvatar = (data: UpdateAvatar): Promise<User> => {
    return new Promise(async (resolve, reject) => {
      try {
        const tokens = await getTokens()
        const fd = new FormData()
        fd.append('avatar', data.file)
        const response = await MidtierService.axiosInstance.post('/users/uploadAvatar', fd, {
          headers: {
            Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
          },
        })
        resolve(response.data.user)
      } catch (e) {
        reject(e)
      }
    })
  }

  public static deleteAvatar = (): Promise<null> => {
    return new Promise(async (resolve, reject) => {
      try {
        const tokens = await getTokens()
        await MidtierService.axiosInstance.post('/users/deleteAvatar', null, {
          headers: {
            Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
          },
        })
        resolve(null)
      } catch (e) {
        reject(e)
      }
    })
  }

  public static upload = (data: {file: File}): Promise<string> => {
    return new Promise(async (resolve, reject) => {
      try {
        const fd = new FormData()
        fd.append('file', data.file)
        const response = await MidtierService.axiosInstance.post('/users/upload', fd)
        resolve(response.data.url)
      } catch (e) {
        reject(e)
      }
    })
  }

  public static sendRequestToCoalitionMember = async (): Promise<User> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      url: '/users/requestToCoalitionMember',
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      methodType: 'post',
    })
    return response.data.user
  }
  public static sendRequestToChemoquantAccess = async (): Promise<User> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      url: '/users/requestToChemoquantAccess',
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      methodType: 'post',
    })
    return response.data.user
  }
  public static getCoalitionRequests = async (): Promise<number> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      url: '/users/coalitionRequests',
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      methodType: 'get',
    })
    return response.data.coalitionRequests
  }

  public static getChemoquantRequests = async (): Promise<number> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      url: '/users/chemoquantRequests',
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      methodType: 'get',
    })
    return response.data.chemoquantRequests
  }

  public static getUsersList = async (): Promise<void> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      url: '/users/userList',
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      methodType: 'get',
    })
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'userList.csv')
    document.body.appendChild(link)
    link.click()
  }

  public static denyCoalitionRequest = async (data: DenyCoalitionRequest): Promise<void> => {
    const tokens = await getTokens()
    await MidtierService.templateMethod({
      url: '/users/denyCoalitionRequest',
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      data,
      methodType: 'put',
    })
    return Promise.resolve()
  }

  public static denyChemoquantRequest = async (data: DenyChemoquantRequest): Promise<void> => {
    const tokens = await getTokens()
    await MidtierService.templateMethod({
      url: '/users/denyChemoquantRequest',
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      data,
      methodType: 'put',
    })
    return Promise.resolve()
  }

  public static deleteUserByAdmin = async (data: DeleteUserByAdmin): Promise<void> => {
    const tokens = await getTokens()
    await MidtierService.templateMethod({
      url: '/users/deleteUserByAdmin',
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      data,
      methodType: 'delete',
    })
    return Promise.resolve()
  }
  public static deleteUserMyself = async (): Promise<void> => {
    const tokens = await getTokens()
    await MidtierService.templateMethod({
      url: '/users/myself',
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      methodType: 'delete',
    })
    return Promise.resolve()
  }

  public static getUserByIdWithAdminPermission = async (userId: string): Promise<User> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      url: `/users/admin/${userId}`,
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      methodType: 'get',
    })
    return response.data.user
  }
  public static getCoalitionMemberById = async (userId: string): Promise<User> => {
    const response = await MidtierService.templateMethod({
      url: `/users/${userId}`,
      methodType: 'get',
    })
    return response.data.user
  }
  public static updateUserByAdmin = async (data: UpdateUserByAdmin): Promise<User> => {
    const tokens = await getTokens()
    const response = await MidtierService.templateMethod({
      url: `/users/updateByAdmin`,
      config: {
        headers: {
          Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
        },
      },
      data,
      methodType: 'put',
    })
    return response.data.user
  }

  public static updateAvatarByAdmin = (data: UpdateAvatarByAdmin): Promise<User> => {
    return new Promise(async (resolve, reject) => {
      try {
        const tokens = await getTokens()
        const fd = new FormData()
        fd.append('avatar', data.file)
        fd.append('userId', data.userId)
        const response = await MidtierService.axiosInstance.post('/users/uploadAvatarByAdmin', fd, {
          headers: {
            Authorization: `Bearer ${tokens?.accessToken} ${tokens?.idToken}`,
          },
        })
        resolve(response.data.user)
      } catch (e) {
        reject(e)
      }
    })
  }
         
  public static getAllCoalitionMembers = async (): Promise<Array<User>> => {
    const response = await MidtierService.templateMethod({
      url: `/users/allCoalitionMembers`,
      methodType: 'get',
    })
    return response.data.members
  }
}
                                           