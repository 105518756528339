import axios, {AxiosError, AxiosRequestConfig} from 'axios'
import Swal from 'sweetalert2'
import {store} from '../redux'
import {delUser} from '../redux/user/actions'
import {addMessage} from '../redux/alerts/actions'
import generateId from '../utils/generateId'
import {delTokens, getTokens, setTokens} from '../utils/tokens'

const url = process.env.REACT_APP_BACK_URL

const midtierAxios = axios.create({
  baseURL: url,
})

const chemoAxios = axios.create({
  baseURL: process.env.REACT_APP_CHEMOSAFE_URI,
})

const isJsonString = (str: string): boolean => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const checkJoiError = (dataMessage: any): string | void => {
  if (
    dataMessage.details &&
    dataMessage.details[0] &&
    dataMessage.details[0].message &&
    typeof dataMessage.details[0].message === 'string'
  ) {
    return dataMessage.details[0].message
  } else if (dataMessage.message && typeof dataMessage.message === 'string') {
    return dataMessage.message
  }
}

const handleError = (e: AxiosError): string => {
  const error = e.response
  if (error && error.data && typeof error.data === 'string') {
    return error.data
  }
  const dataMessage = error && error.data && error.data.message
  if (dataMessage) {
    if (dataMessage.message && typeof dataMessage.message === 'string') {
      return dataMessage.message
    }
    if (typeof dataMessage === 'string') {
      if (isJsonString(dataMessage)) {
        return JSON.parse(dataMessage).error_description
      }
      return dataMessage
    } else {
      const joiMessage = checkJoiError(dataMessage)
      if (joiMessage) return joiMessage
    }
  }
  return 'Something went wrong :('
}

const requestHandler = async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  try {
    const tokens = await getTokens()
    if (tokens && new Date().getTime() + 1000000 > new Date(tokens.expiresAt).getTime()) {
      const response = await axios.post(`${url}/users/refreshTokens`, {
        refreshToken: tokens.refreshToken,
      })
      const newTokens = response.data.tokens
      await setTokens({
        accessToken: newTokens.accessToken,
        idToken: newTokens.idToken,
        expiresAt: newTokens.expiresAt,
        refreshToken: newTokens.refreshToken,
        tokenType: newTokens.tokenType,
      })
      request.headers.Authorization = `Bearer ${newTokens.accessToken} ${newTokens.idToken}`
    }
    return request
  } catch (e) {
    return request
  }
}

const responseErrorHandling = async (error: AxiosError): Promise<AxiosError | never> => {
  if (error.response && error.response.status === 401) {
    await Swal.fire({
      title: 'Session Expired',
      text: 'Your session has expired. Please, login again.',
      icon: 'error',
      confirmButtonText: 'Ok',
    })
    delTokens()
    store.dispatch(delUser())
    // @ts-ignore
    window.location = '/'
  }
  const errorMessage = handleError(error)
  const errorMessageWithUppercaseFirstLetter = errorMessage[0].toUpperCase() + errorMessage.slice(1)
  const id = generateId()
  store.dispatch(addMessage({message: errorMessageWithUppercaseFirstLetter, id, type: 'error'}))
  throw new Error(errorMessageWithUppercaseFirstLetter)
}

midtierAxios.interceptors.request.use((request) => requestHandler(request))
midtierAxios.interceptors.response.use(
  (response) => response,
  (error) => responseErrorHandling(error),
)
export {midtierAxios, chemoAxios}
