import React, {useEffect, useState} from 'react'
import Loader from 'react-loader-spinner'
import {Button, Input, Select, Tag} from '../../../../components'
import {MidtierService} from '../../../../services'
import countries from '../../../../constants/countries'
import positions from '../../../../constants/positions'
import {User} from '../../../../services/types'
import st from './CoalitionFinder.module.scss'
import MemberCards from './components/MemberCards'
import newMembers from './constants/newMembers'

type CountriesAndPositions = Array<{name: string; value: string}>
const getMembersCountries = (members: User[]): CountriesAndPositions => {
  const memberCountries = members.reduce((total: string[], member: User) => {
    if (total.includes(member.country)) {
      return total
    }
    return [...total, member.country]
  }, [])
  return memberCountries.reduce((total: CountriesAndPositions, country) => {
    const findCountry = countries.find((countryFromArray) => countryFromArray.value === country)
    if (findCountry) {
      return [...total, findCountry]
    }
    return total
  }, [])
}

const getMembersPositions = (members: User[]): CountriesAndPositions => {
  const memberCountries = members.reduce((total: string[], member: User) => {
    if (total.includes(member.position)) {
      return total
    }
    return [...total, member.position]
  }, [])
  return memberCountries.reduce((total: CountriesAndPositions, country) => {
    const findCountry = positions.find((countryFromArray) => countryFromArray.value === country)
    if (findCountry) {
      return [...total, findCountry]
    }
    return total
  }, [])
}

const CoalitionFinder = () => {
  const [members, setMembers] = useState<Array<User>>([])
  const [countries, setCountries] = useState<CountriesAndPositions>([])
  const [positions, setPositions] = useState<CountriesAndPositions>([])
  const [isLoading, setIsLoading] = useState(false)
  const [activeFilters, setActiveFilters] = useState({country: '', position: ''})
  const [name, setName] = useState('')
  const [displayedOptions, setDisplayedOptions] = useState({number: 8, step: 8, default: 8})
  useEffect(() => {
    const getMembers = async () => {
      try {
        setIsLoading(true)
        const members = await MidtierService.getAllCoalitionMembers()
        // todo temporary solution for newMembers, delete newMembers
        // @ts-ignore
        setMembers([...members, ...newMembers])
        setCountries(getMembersCountries(members))
        setPositions(getMembersPositions(members))
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
      }
    }
    getMembers()
  }, [])

  const filteredMembers = members
    .filter((member) => {
      const {country, position} = activeFilters
      return (
        (country === '' || country === member.country) &&
        (position === '' || position === member.position) &&
        (member.lastName.toLowerCase().includes(name.toLowerCase()) ||
          member.firstName.toLowerCase().includes(name.toLowerCase()))
      )
    })
    .sort((a) => {
      return a.publicProfile?.imgUrl ? -1 : 1
    })

  const displayedMembers = filteredMembers.slice(0, displayedOptions.number)
  const handleChange = (fieldName: 'name' | 'position' | 'country') => (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const field = e.target
    fieldName === 'name' ? setName(field.value) : setActiveFilters({...activeFilters, [fieldName]: field.value})
    setDisplayedOptions({...displayedOptions, number: displayedOptions.default})
  }

  const convertArrayToObjectOfResetedFilters = (filterNames: Array<string>) => {
    return filterNames.reduce((acc, value) => ({...acc, [value]: ''}), {})
  }

  const handleResetFilters = (filters?: Array<string>) => () => {
    filters
      ? setActiveFilters({...activeFilters, ...convertArrayToObjectOfResetedFilters(filters)})
      : setActiveFilters({...activeFilters, ...convertArrayToObjectOfResetedFilters(Object.keys(activeFilters))})
  }

  const handleShowMore = (step: number) => () => {
    setDisplayedOptions({...displayedOptions, number: displayedOptions.number + step})
  }

  return isLoading ? (
    <Loader className={st.spinner} type="TailSpin" color="#eb7346" height={300} width={300} visible={true} />
  ) : (
    <div className={st.coalitionFinder}>
      <div className={st.inputs}>
        <Input
          id="name"
          placeholder="Type the name here"
          inputContainerClassName={st.searchInputContainer}
          onChange={handleChange('name')}
          rightIcon={
            <div>
              <img src={`${process.env.PUBLIC_URL}${'icons/search.svg'}`} alt="" />
            </div>
          }
        />
        <div className={st.selectsBlock}>
          <Select
            selectContainerClassName={st.selectContainer}
            id="country"
            placeholder="Country"
            onChange={handleChange('country')}
            options={[...countries].sort((a, b) => a.name.localeCompare(b.name))}
            value={activeFilters.country}
          />
          <Select
            selectContainerClassName={st.selectContainer}
            id="position"
            placeholder="Position"
            onChange={handleChange('position')}
            options={[...positions].sort((a, b) => a.name.localeCompare(b.name))}
            value={activeFilters.position}
          />
        </div>
      </div>
      <div className={st.tags}>
        {activeFilters.country !== '' && (
          <Tag
            name={countries.find((country) => country.value === activeFilters.country)?.name}
            onClose={handleResetFilters(['country'])}
          />
        )}
        {activeFilters.position !== '' && (
          <Tag
            name={positions.find((position) => position.value === activeFilters.position)?.name}
            onClose={handleResetFilters(['position'])}
            color="#5f0f37"
          />
        )}
        {activeFilters.country !== '' && activeFilters.position !== '' && (
          <Tag name="Clear all" onClick={handleResetFilters()} color="#eb7346" />
        )}
      </div>
      <MemberCards membersList={displayedMembers} />
      <div className={st.footer}>
        {filteredMembers.length > displayedOptions.number && (
          <Button onClick={handleShowMore(displayedOptions.step)} label="Show more" className={st.showMoreButton} />
        )}
      </div>
    </div>
  )
}

export default CoalitionFinder
