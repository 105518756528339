import React from 'react'
import {
  TextField,
  FormControl,
  InputLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Select,
} from '@material-ui/core'
import {Autocomplete} from '@material-ui/lab'
import {useFormik} from 'formik'
import classNames from 'classnames'
import {validator} from '../../../../../../../utils/validation/validator'
import {useCSTContext} from '../../../../../context/chemosafeToolContext'
import {useStyles} from './styles'

interface OptionProps {
  id?: string
  name?: string
}

const INITIAL_VALUES = {
  questionType: 'BOOLEAN',
  showInAssessment: true,
  questionText: '',
  parentAnswer: 'YES',
  parentQuestion: '',
  recommendationText: '',
  resources: {
    resourceId: '',
    resourceRequirement: 'Low',
    referenceId: '',
  },
  conditionalPredicate: {
    value: {
      questionId: '',
      optionId: '',
    },
  },
  planType: 'Administrative Controls',
}

const validate = (values: FormValues) =>
  validator.rules({
    questionText: validator.validate(values.questionText).required(),
    recommendationText: validator.validate(values.recommendationText).required(),
  })

type FormValues = typeof INITIAL_VALUES

interface RemoveQuestionDialogProps {
  isOpen: boolean
  onSubmit: (values: any) => void
  onClose: () => void
}

// eslint-disable-next-line max-lines-per-function
const AddQuestionDialog = ({isOpen, onSubmit, onClose}: RemoveQuestionDialogProps) => {
  const {dashboard, resources}: any = useCSTContext()
  const classes = useStyles()

  const formik = useFormik<FormValues>({
    initialValues: INITIAL_VALUES,
    validate,
    onSubmit: (values) => {
      const newQuestion = {...values}
      if (newQuestion.parentQuestion) {
        newQuestion.conditionalPredicate = {
          value: {
            questionId: newQuestion.parentQuestion,
            optionId: newQuestion.parentAnswer,
          },
        }
      } else {
        delete newQuestion.conditionalPredicate
      }
      delete newQuestion.parentQuestion
      delete newQuestion.parentAnswer

      formik.resetForm({values: INITIAL_VALUES})
      onSubmit?.(newQuestion)
    },
  })

  const handleClose = () => {
    onClose?.()
    formik.resetForm({values: INITIAL_VALUES})
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title" classes={{paper: classes.paper}}>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers>
          <DialogContentText>Please, enter the following information:</DialogContentText>
          <TextField
            onChange={formik.handleChange}
            value={formik.values.questionText || ''}
            error={!!formik.errors.questionText}
            helperText={formik.errors.questionText}
            autoFocus
            margin="dense"
            id="questionText"
            label="Question Text"
            type="text"
            variant="outlined"
            fullWidth
            multiline
          />
          <TextField
            onChange={formik.handleChange}
            value={formik.values.recommendationText || ''}
            error={!!formik.errors.recommendationText}
            helperText={formik.errors.recommendationText}
            margin="dense"
            id="recommendationText"
            label="Recommendation"
            type="text"
            variant="outlined"
            fullWidth
            multiline
          />
          <FormControl variant="outlined" className={classNames(classes['add-question-select'])}>
            <InputLabel>COST</InputLabel>
            <Select
              native
              label="COST"
              onChange={formik.handleChange}
              inputProps={{
                id: 'resources.resourceRequirement',
              }}
            >
              <option value="Low">Low</option>
              <option value="Medium">Medium</option>
              <option value="High">High</option>
            </Select>
          </FormControl>
          <Autocomplete
            options={resources}
            getOptionLabel={(option: OptionProps) => option?.name || ''}
            onChange={(e, value) => {
              formik.setFieldValue('resources.resourceId', value?.id)
            }}
            renderInput={(params) => (
              <TextField {...params} label="Resource" variant="outlined" margin="dense" fullWidth />
            )}
          />
          <Autocomplete
            onChange={(e, value: OptionProps | null) => {
              formik.setFieldValue('resources.referenceId', value?.id)
            }}
            options={resources}
            getOptionLabel={(option: OptionProps | null) => option?.name || ''}
            renderInput={(params) => <TextField {...params} label="Reference" margin="dense" variant="outlined" />}
          />
          <Autocomplete
            onChange={(e, value) => {
              formik.setFieldValue('parentQuestion', value)
            }}
            options={dashboard?.order?.root}
            getOptionLabel={(option: string) => option}
            renderInput={(params) => (
              <TextField {...params} label="Parent question" margin="dense" variant="outlined" />
            )}
          />
          <FormControl
            className={classNames(classes['add-question-select'])}
            disabled={!formik.values.parentQuestion}
            variant="outlined"
          >
            <InputLabel>Show when the parent answer is</InputLabel>
            <Select
              native
              label="Show when the parent answer is"
              onChange={formik.handleChange}
              value={formik.values.parentAnswer || ''}
              inputProps={{
                name: 'parentAnswer',
                id: 'parentAnswer',
              }}
            >
              <option value="NO">NO</option>
              <option value="YES">YES</option>
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            className={classNames(classes['add-question-select'])}
            style={{display: 'block'}}
          >
            <InputLabel>Action Plan Type</InputLabel>
            <Select
              native
              onChange={formik.handleChange}
              style={{width: 537, fontSize: '0.875rem'}}
              labelWidth={130}
              inputProps={{
                name: 'planType',
                id: 'planType',
              }}
            >
              <option value="Administrative Controls">Administrative Controls</option>
              <option value="Personal Protective Equipment (PPE) Controls">
                Personal Protective Equipment (PPE) Controls
              </option>
              <option value="Engineering Controls">Engineering Controls</option>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" type="submit" disabled={!formik.isValid}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AddQuestionDialog
